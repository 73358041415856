import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { categoriesWithCountSelector } from 'store/selectors';
import { useQuery } from 'hooks';
import { Link } from 'react-router-dom';
import { buildQueryString } from 'helpers';
import PropTypes from 'prop-types';

import './Breadcrumb.sass';

function Breadcrumb({
  isTemplateOwner = false,
  system,
  status,
  isEdit = false,
  isPreview = false,
  onTogglePreview,
  templateStatus = '',
  templateIsShared = false,
}) {
  const intl = useIntl();

  const { embed, category } = useQuery();

  const categories = useSelector(categoriesWithCountSelector);
  const currentCategory = categories.filter((item) => item.id === category);

  const params = system
    ? { category: 'all', embed }
    : { status: 'published', embed };

  const homeSearch = buildQueryString(params);
  const templateStatusSearch = new URLSearchParams({
    status: templateIsShared
      ? `${isTemplateOwner ? 'draft' : 'shared'}`
      : templateStatus,
  });

  const messages = defineMessages({
    breadcrumbUserTemplatesTitle: { id: 'breadcrumb.user-templates-title' },
    breadcrumbSystemTemplatesTitle: { id: 'breadcrumb.system-templates-title' },
    breadcrumbCurrentTitleSuffix: { id: 'breadcrumb.current-title-suffix' },
    breadcrumbCategoryTitlePrefix: { id: 'breadcrumb.category-title-prefix' },
  });

  return (
    <div aria-label="email breadcrumb" className="dnd-breadcrumb">
      {isPreview ? (
        <>
          <Link to={{ pathname: '/', search: homeSearch }}>
            {intl.formatMessage(
              system
                ? messages.breadcrumbSystemTemplatesTitle
                : messages.breadcrumbUserTemplatesTitle,
            )}
          </Link>
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              onTogglePreview();
            }}>
            <FormattedMessage id="breadcrumb.current-edit-template" />
          </a>
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          <strong>
            <FormattedMessage id="breadcrumb.preview-title" />
          </strong>
        </>
      ) : (
        <>
          <Link to={{ pathname: '/', search: homeSearch }}>
            {intl.formatMessage(
              system
                ? messages.breadcrumbSystemTemplatesTitle
                : messages.breadcrumbUserTemplatesTitle,
            )}
          </Link>
          {!system && (templateIsShared || templateStatus) && (
            <>
              <span className="dnd-breadcrumb__separator">
                <span className="icon icon-chevron-right" />
              </span>
              <Link
                className="dnd-breadcrumb__template-status"
                to={{ pathname: '/', search: String(templateStatusSearch) }}>
                {templateIsShared
                  ? intl.formatMessage({
                      id: isTemplateOwner
                        ? 'breadcrumb.dashboard-owner-shared-status-title'
                        : 'breadcrumb.dashboard-shared-status-title',
                    })
                  : templateStatus}
              </Link>
            </>
          )}
          <span className="dnd-breadcrumb__separator">
            <span className="icon icon-chevron-right" />
          </span>
          <strong>
            {isEdit ? (
              <FormattedMessage id="breadcrumb.current-edit-template" />
            ) : system ? (
              category === 'all' ? (
                `${category} ${intl.formatMessage(
                  messages.breadcrumbCurrentTitleSuffix,
                )}`
              ) : (
                `${intl.formatMessage(
                  messages.breadcrumbCategoryTitlePrefix,
                )}: ${currentCategory.map((item) => item.name)}`
              )
            ) : (
              `${status} ${intl.formatMessage(
                messages.breadcrumbCurrentTitleSuffix,
              )}`
            )}
          </strong>
        </>
      )}
    </div>
  );
}

Breadcrumb.propTypes = {
  isTemplateOwner: PropTypes.bool,
  status: PropTypes.string.isRequired,
  system: PropTypes.bool,
  isEdit: PropTypes.bool,
  isPreview: PropTypes.bool,
  onTogglePreview: PropTypes.func,
  templateStatus: PropTypes.string,
  templateIsShared: PropTypes.bool,
};

export default Breadcrumb;
