import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSettings } from 'store/actions';
import { userDndMode, principalIdSelector } from 'store/selectors';
import BaseModal from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useModal } from 'hooks';
import cx from 'classnames';
import './EditorMode.sass';

function EditorMode({ version }) {
  const dispatch = useDispatch();

  const [selectedMode, setSelectedMode] = useState('');

  const {
    isOpen: isConfirmModeModalOpen,
    open: openConfirmModeModal,
    close: closeConfirmModeModal,
  } = useModal();

  const userId = useSelector(principalIdSelector);
  const currentMode = useSelector(userDndMode);

  const handleChangeMode = async () => {
    try {
      const payload = {
        userId,
        dnd_mode: selectedMode,
      };

      await dispatch(updateUserSettings(payload));

      closeConfirmModeModal();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch {
      // catching error and doing nothing
    }
  };

  return (
    <div className="editor-mode">
      <div className="editor-mode__mode">
        <button
          disabled={currentMode === 'simple'}
          onClick={() => {
            setSelectedMode('simple');
            openConfirmModeModal();
          }}
          className={cx('simple-mode', {
            current: currentMode === 'simple',
          })}>
          <FormattedMessage id="editor-mode.simple" />
        </button>
        <button
          disabled={currentMode === 'advanced'}
          onClick={() => {
            setSelectedMode('advanced');
            openConfirmModeModal();
          }}
          className={cx('advanced-mode', {
            current: currentMode === 'advanced',
          })}>
          <FormattedMessage id="editor-mode.advanced" />
        </button>
      </div>
      <div className="editor-mode__ver">v{version}</div>
      <BaseModal
        isOpen={isConfirmModeModalOpen}
        headerTitle="editor-mode.modal-title"
        maxWidth="600px"
        onClose={closeConfirmModeModal}>
        <ConfirmModal
          loading={false}
          buttonsColor="secondary"
          onClose={closeConfirmModeModal}
          onConfirm={handleChangeMode}>
          <FormattedMessage id="editor-mode.confirm-description" />
        </ConfirmModal>
      </BaseModal>
    </div>
  );
}

EditorMode.propTypes = {
  version: PropTypes.string.isRequired,
};

export default EditorMode;
