import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminSelector, principalIdSelector } from 'store/selectors';
import { updateSharedTemplateLockStatus } from 'store/actions';
import BaseModal from 'components/Modals/BaseModal';
import TemplateInfoModal from 'components/Modals/TemplateInfoModal';
import Logo from 'components/Logo';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import ReactTooltip from 'react-tooltip';
import { useQuery, useToggleTypes } from 'hooks';

import { TEMPLATE_STATUS, VERSION } from 'appConstants';
import { buildSharedTemplateViewUrl, closeEmbedEditor } from 'helpers';
import cx from 'classnames';
import EditorMode from 'components/EditorMode';

import './EditorHeader.sass';

function EditorHeader({
  template,
  isConvertLoading,
  isUpdateLoading,
  isStatusLoading,
  haveChanges,
  show,
  onShow,
  onConvertToSystem,
  onChangeStatus,
  onSaveTemplate,
  onChangeInfo,
  onRestartEditorTour,
  onToggleTestEmail,
  onTogglePreview,
}) {
  const { embed, system } = useQuery();

  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminSelector);
  const currentUser = useSelector(principalIdSelector);

  const history = useHistory();

  const templateIsSharedWithCurrentUser =
    template.isShared &&
    template.userId !== currentUser &&
    template.userId !== template.collaboratorId &&
    !template.collaboratorsList.includes(template.userId);

  const templateIsSharedOwner =
    template.isShared && template.userId === currentUser;

  const handleCloseInfo = () => {
    onShow(useToggleTypes.showInfoModal);
  };

  const loadingContent = () => {
    return (
      <>
        <FormattedMessage id="button.loading" />
        <span className="icon icon-spinner" />
      </>
    );
  };

  const renderUpdateButton = (templateIsSharedWithCurrentUser) => {
    return (
      <>
        <Button
          variant="contained"
          color={system ? 'secondary' : 'default'}
          className="editor-header__update-btn"
          disabled={isUpdateLoading}
          onClick={onSaveTemplate}>
          {isUpdateLoading ? (
            loadingContent()
          ) : (
            <>
              <FormattedMessage
                id={`${
                  embed
                    ? 'button.save-and-continue'
                    : `button.${
                        templateIsSharedWithCurrentUser
                          ? 'save-changes'
                          : 'save'
                      }`
                }`}
              />
              <span className="icon icon-save" />
            </>
          )}
        </Button>
        {template.status === TEMPLATE_STATUS.PUBLISHED && !embed && !system && (
          <Button
            variant="contained"
            className="editor-header__update-btn"
            disabled={isStatusLoading || haveChanges}
            onClick={() => onChangeStatus()}>
            {isStatusLoading ? (
              loadingContent()
            ) : (
              <>
                <FormattedMessage id="button.unpublish" />
                <span className="icon icon-unpublish" />
              </>
            )}
          </Button>
        )}
        {!template.isShared &&
          template.status === TEMPLATE_STATUS.DRAFT &&
          !embed &&
          !system && (
            <Button
              variant="contained"
              className="editor-header__update-btn"
              disabled={isStatusLoading || haveChanges}
              onClick={async () => onChangeStatus()}>
              {isStatusLoading ? (
                loadingContent()
              ) : (
                <>
                  <FormattedMessage id="button.publish" />
                  <span className="icon icon-publish" />
                </>
              )}
            </Button>
          )}
        {templateIsSharedWithCurrentUser &&
          template.status === TEMPLATE_STATUS.DRAFT &&
          !system && (
            <>
              <Button
                variant="contained"
                className="editor-header__view-btn"
                onClick={() =>
                  window.open(buildSharedTemplateViewUrl(template.id))
                }>
                <>
                  <span className="icon icon-visibility" />
                  <FormattedMessage id="button.view" />
                </>
              </Button>
              {template.isShared && template.userId !== currentUser && (
                <Button
                  variant="contained"
                  className="editor-header__unlock-btn"
                  onClick={() => {
                    dispatch(
                      updateSharedTemplateLockStatus({
                        id: template.id,
                        lockStatus: false,
                      }),
                    );

                    history.push({
                      pathname: '/',
                      search: String(
                        new URLSearchParams({
                          status: 'shared',
                        }),
                      ),
                    });
                  }}>
                  <>
                    <span className="icon icon-lock-open" />
                    <FormattedMessage id="button.unlock" />
                  </>
                </Button>
              )}
            </>
          )}
        {template.status === TEMPLATE_STATUS.DELETED && (
          <Button
            variant="contained"
            className="editor-header__update-btn"
            disabled={isStatusLoading}
            onClick={onChangeStatus}>
            {isStatusLoading ? (
              loadingContent()
            ) : (
              <>
                <FormattedMessage id="button.restore" />
                <span className="icon icon-restore-from-trash" />
              </>
            )}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <header
        className={cx('editor-header', {
          'template-shared-owner': templateIsSharedOwner,
          'template-shared': templateIsSharedWithCurrentUser,
        })}>
        <div className="editor-header__column editor-header__app-actions">
          {embed ? (
            <Logo />
          ) : (
            <>
              <Link to={{ pathname: '/' }}>
                <Logo />
              </Link>
              <Breadcrumb
                isTemplateOwner={templateIsSharedOwner}
                status={template.status}
                system={Boolean(system)}
                isEdit
                templateStatus={template.status}
                templateIsShared={template.isShared}
              />
            </>
          )}
        </div>
        <div className="editor-header__column editor-header__template-actions">
          {!templateIsSharedWithCurrentUser && (
            <>
              <div className="editor-header__panel-template-options" />
              <IconButton
                aria-label="restart editor tour"
                className="editor-header__help-btn"
                onClick={onRestartEditorTour}>
                <span className="icon icon-help" />
              </IconButton>
            </>
          )}
          <div className="editor-header__template-info">
            {templateIsSharedWithCurrentUser ? (
              <div className="editor-header__template-info__shared-template-title">
                {template.name}
              </div>
            ) : (
              <Button
                data-testid={template.name}
                className="editor-header__simple-btn editor-header__template-name"
                onClick={() => onShow(useToggleTypes.showInfoModal)}>
                <span
                  data-tip
                  data-for="template-info-name-tooltip"
                  className="editor-header__template-name__name">
                  {template.name}
                </span>
                <ReactTooltip
                  id="template-info-name-tooltip"
                  class="title-tooltip"
                  type="dark"
                  aria-haspopup="true"
                  place="bottom"
                  clickable={false}
                  delayShow={500}
                  border={false}
                  effect="solid">
                  <span>{template.name}</span>
                </ReactTooltip>
                <span className="icon icon-expand-more" />
              </Button>
            )}
          </div>
          {!templateIsSharedWithCurrentUser && (
            <>
              <div className="editor-header__panel-preview">
                <Button
                  className="editor-header__simple-btn"
                  onClick={onToggleTestEmail}>
                  <span className="icon icon-mail" />
                  <FormattedMessage id="button.test-email" />
                </Button>
              </div>
              <EditorMode version={VERSION} />
            </>
          )}
        </div>
        <div className="editor-header__column editor-header__user-actions">
          {isAdmin &&
            template.status === TEMPLATE_STATUS.PUBLISHED &&
            !template.isShared &&
            !system && (
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  isConvertLoading ||
                  isUpdateLoading ||
                  isStatusLoading ||
                  haveChanges
                }
                loading={isConvertLoading}
                onClick={onConvertToSystem}
                className="editor-header__convert-btn">
                <FormattedMessage id="button.convert-to-system" />
              </Button>
            )}
          {!templateIsSharedWithCurrentUser && (
            <Button
              className="editor-header__preview-btn"
              variant="contained"
              color="default"
              onClick={onTogglePreview}>
              <FormattedMessage id="button.preview-changes" />
              <span className="icon icon-visibility" />
            </Button>
          )}
          {renderUpdateButton(templateIsSharedWithCurrentUser)}
          {embed && (
            <Button
              className="editor-header__cancel-btn"
              onClick={() => closeEmbedEditor()}>
              <FormattedMessage id="button.cancel" />
            </Button>
          )}
        </div>
      </header>
      <BaseModal
        isOpen={show.showInfoModal}
        onClose={handleCloseInfo}
        iconTitleClass="icon icon-settings"
        style={{
          width: 500,
        }}
        headerTitle="template-info-modal.header-title"
        contentClass="template-info-modal">
        <TemplateInfoModal
          name={template.name}
          description={template.description}
          preHeader={template.preHeader}
          tags={template.tags}
          categories={template.categories}
          onClose={handleCloseInfo}
          onChange={onChangeInfo}
        />
      </BaseModal>
    </>
  );
}

EditorHeader.propTypes = {
  template: PropTypes.object.isRequired,
  isConvertLoading: PropTypes.bool.isRequired,
  isUpdateLoading: PropTypes.bool.isRequired,
  isStatusLoading: PropTypes.bool.isRequired,
  haveChanges: PropTypes.bool.isRequired,
  show: PropTypes.object.isRequired,
  onShow: PropTypes.func.isRequired,
  onConvertToSystem: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onSaveTemplate: PropTypes.func.isRequired,
  onChangeInfo: PropTypes.func.isRequired,
  onRestartEditorTour: PropTypes.func.isRequired,
  onToggleTestEmail: PropTypes.func.isRequired,
  onTogglePreview: PropTypes.func.isRequired,
};

export default EditorHeader;
