import loadBlocks from './blocks';
import components from './components';

export default (editor, opts = {}, currentMode) => {
  const config = {
    category: 'Sections',
    labelColumn3: '3 Columns',
    labelColumn4: '4 Columns',
    labelColumn37: '2 Columns (3/7)',
    labelColumn84: '2 Columns (8/4)',
    labelColumn48: '2 Columns (4/8)',
    labelColumn93: '2 Columns (9/3)',
    labelColumn39: '2 Columns (3/9)',
    pageWrapperType: 'root',
    pageWrapperBodyType: 'root-body',
    pageWrapperRowType: 'root-row',
    pageWrapperCellType: 'root-cell',
    sectionType: 'section',
    spacerType: 'spacer',
    dividerType: 'divider',
    ...opts,
  };

  // Add blocks
  currentMode === 'advanced' && loadBlocks(editor, config);

  // add components
  components(editor, config);
};
