import { BLOCKS_CATEGORIES, CDN_HOST } from 'appConstants';
import social from './Social';
import basic from './Basic';
import extra from './Extra';
import sections from './Sections';

export default (
  editor,
  createCustomCode,
  editCustomCode,
  currentMode,
  dispatch,
  templateId,
) => {
  const baseUrl = CDN_HOST || window.origin; // in case env variable isn't added

  basic(
    editor,
    {
      category: 'Basic',
      baseUrl,
      imageType: 'image',
      imageTextType: 'image-text',
      linkedImageType: 'linked-image',
      linkedImageImageType: 'linked-image-image',
      imageBlockType: 'image-block',
      buttonType: 'button',
      iconWrapperType: 'icon-wrapper',
      iconLinkType: 'icon-link',
      iconImageType: 'icon-image',
      linkType: 'link',
      videoType: 'embed-video',
      menuWrapperType: 'menu-wrapper',
      socialIconWrapperType: 'social-icon-wrapper',
      socialIconType: 'social-icon',
      timerType: 'timer',
    },
    currentMode,
  );
  sections(editor, { category: 'Sections', baseUrl }, currentMode);
  extra(
    editor,
    {
      category: 'Extra',
      baseUrl,
      createCustomCode,
      editCustomCode,
      customCodeType: 'custom',
      formDataTableType: 'form-data-table',
      discountCodeType: 'discount-code',
      qrCodeType: 'qr-code',
    },
    currentMode,
    dispatch,
    templateId,
  );
  social(
    editor,
    {
      category: 'Social',
      baseUrl,
      shape: 'square',
      color: 'color',
      size: '32',
      socialLinkTraitType: 'social-link',
      socialFollowType: 'social-follow',
      socialFollowBlockType: 'social-follow-block',
    },
    currentMode,
  );

  editor.BlockManager.getCategories().each((cat) => {
    if (
      BLOCKS_CATEGORIES.modules &&
      BLOCKS_CATEGORIES?.modules?.includes(cat.id) &&
      cat.id !== BLOCKS_CATEGORIES.modules[0]
    ) {
      cat.set('open', false);
    }
  });
};
