export const panelsConfig = (currentMode, system) => {
  const data = {
    defaults: [
      {
        id: 'template-options',
        el: '.editor-header__panel-template-options',
        buttons: [
          {
            id: 'general-settings',
            className: 'icon icon-settings',
            command: 'open-general-settings',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'General Style Settings',
              'data-user-guide-step': 'settings',
            },
          },
          {
            id: 'view-code-modal',
            className: 'icon icon-code',
            command: 'view-code-modal',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'View Code',
            },
          },
          {
            id: 'open-import-modal',
            className: 'icon icon-cloud-download',
            command: 'open-import-modal',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Import ',
            },
          },
          ...(!system
            ? [
                {
                  id: 'export-modal',
                  className: 'icon icon-ios-share',
                  command: 'export-modal',
                  attributes: {
                    'data-tooltip-pos': 'bottom',
                    'data-tooltip': 'export ',
                  },
                },
              ]
            : []),
          {
            id: 'canvas-clear',
            className: 'icon icon-delete',
            command: 'clean',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Clean ',
            },
          },
        ],
      },
      {
        id: 'blocks-tabs',
        el: '.editor-content__panel-blocks-tabs',
        buttons: [
          {
            id: 'blocks',
            currentMode,
            label:
              '<span class="icon icon-extension"></span><span>Blocks</span>',
            command: 'custom-open-blocks',
            togglable: false,
          },
          {
            id: 'modules',
            currentMode,
            label:
              '<span class="icon icon-view-compact"></span><span>Modules</span>',
            command: 'custom-open-blocks',
            togglable: false,
          },
          {
            id: 'waymore',
            currentMode,
            label:
              '<span class="icon icon-electric-bolt"></span><span>Advanced</span>',
            command: 'custom-open-blocks',
            togglable: false,
          },
        ],
      },
      {
        id: 'canvas-controls',
        el: '.editor-content__panel-canvas-controls',
        buttons: [
          {
            id: 'sw-visibility',
            command: 'sw-visibility',
            className: 'icon icon-border-clear',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Show Borders',
            },
          },
          {
            id: 'personal-note',
            command: (e) => e.runCommand('personal-notes'),
            className: 'icon icon-add-comment',
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Personal note',
            },
          },
        ],
      },
      {
        id: 'canvas-history',
        el: '.editor-content__panel-canvas-history',
        buttons: [
          {
            id: 'undo',
            className: 'icon icon-undo',
            command: 'core:undo',
            disable: true,
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Undo',
            },
          },
          {
            id: 'history',
            className: 'icon icon-history',
            command: (e) => e.runCommand('version-history'),
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Version history',
            },
          },
          {
            id: 'redo',
            className: 'icon icon-redo',
            command: 'core:redo',
            disable: true,
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Redo',
            },
          },
        ],
      },
      {
        id: 'responsive-actions',
        el: '.editor-content__panel-responsive-actions',
        buttons: [
          {
            id: 'preview-desktop',
            className: 'icon icon-desktop-windows',
            command: 'set-desktop',
            togglable: false,
            active: true,
          },
          {
            id: 'preview-tablet',
            className: 'icon icon-tablet-mac',
            command: 'set-tablet',
            togglable: false,
          },
          {
            id: 'preview-mobile',
            className: 'icon icon-phone-iphone',
            command: 'set-mobile',
            togglable: false,
          },
        ],
      },
      {
        id: 'zoom-actions',
        el: '.editor-content__panel-zoom-actions',
        buttons: [
          {
            id: 'editor-zoom-reset',
            className: 'icon icon-youtube-searched-for',
            command: 'editor-zoom-reset',
            togglable: false,
            active: false,
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Zoom reset',
            },
          },
          {
            id: 'editor-zoom-in',
            className: 'icon icon-zoom-in',
            command: 'editor-zoom-in',
            togglable: false,
            active: false,
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Zoom in',
            },
          },
          {
            id: 'editor-zoom-out',
            className: 'icon icon-zoom-out',
            command: 'editor-zoom-out',
            togglable: false,
            active: false,
            attributes: {
              'data-tooltip-pos': 'bottom',
              'data-tooltip': 'Zoom out',
            },
          },
        ],
      },
      {
        id: 'traits-tabs',
        el: '.editor-content__panel-traits-tabs',
        buttons: [
          {
            id: 'open-sm-custom',
            command: 'open-sm-custom',
            label:
              '<span class="icon icon-settings"></span><span>Settings & Styles</span>',
            togglable: false,
          },
        ],
      },
      {
        id: 'views-container',
        el: '.editor-content__traits-container',
      },
    ],
  };

  return data;
};
