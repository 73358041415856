import {
  setButtonSector,
  setColumnSector,
  setIconSector,
  setImageBlockSector,
  setImageSector,
  setImageTextSector,
  setPageWrapperSector,
  setSectionSector,
  setTextSector,
  setVideoSector,
  setWrapperSector,
} from '../../utils';

export const listeners = (editor, currentMode) => {
  const pn = editor.Panels;

  const addCustomToolbarButtons = (component) => {
    const model = component.model;
    const id = component.id;
    const init = model.prototype.init;

    editor.DomComponents.addType(id, {
      model: {
        init(...args) {
          init.apply(this, args);
          const toolbar = this.get('toolbar');
          const blockType = this.get('type');

          toolbar.forEach((button) => {
            switch (button.command) {
              case 'tlb-move':
                button.attributes = {
                  ...button.attributes,
                  title: 'Move',
                };
                break;
              case 'tlb-clone':
                button.attributes = {
                  ...button.attributes,
                  title: 'Duplicate',
                };
                break;
              case 'tlb-delete':
                button.attributes = {
                  ...button.attributes,
                  title: 'Delete',
                };
                break;
              case 'custom-code-modal':
                button.attributes.title = 'Edit custom code';
                break;
              case 'custom-code-lock':
                button.attributes.title =
                  'Edit custom code is not enabled in your plan';
                break;
              default:
                // because "select parent" button doesn't have string command
                // we use default case
                button.attributes = {
                  ...button.attributes,
                  title: 'Select parent component',
                };
                break;
            }
          });

          // Remove clone form menu item block
          if (blockType === 'link' && this.closestType('menu-wrapper')) {
            const cloneIndex = toolbar.findIndex(
              (item) => item.command === 'tlb-clone',
            );
            const moveIndex = toolbar.findIndex(
              (item) => item.command === 'tlb-move',
            );

            if (cloneIndex !== -1) {
              toolbar.splice(cloneIndex, 1);
            }
            if (moveIndex !== -1) {
              toolbar.splice(moveIndex, 1);
            }
          }

          // Adding move up button
          const hasMoveUpButton = toolbar.some(function (button) {
            return button.command === 'move-up-block';
          });

          if (!hasMoveUpButton) {
            toolbar.push({
              attributes: {
                class: 'icon-move-selection-up',
                title: 'Move up',
              },
              command: 'move-up-block',
            });
          }

          // Adding move down button
          const hasMoveDownButton = toolbar.some(function (button) {
            return button.command === 'move-down-block';
          });

          if (!hasMoveDownButton) {
            toolbar.push({
              attributes: {
                class: 'icon-move-selection-down',
                title: 'Move down',
              },
              command: 'move-down-block',
            });
          }

          // adding edit prodcut button
          const editProductCommand = 'edit-product';
          const hasEditProductButton = toolbar.some(function (button) {
            return button.command === editProductCommand;
          });

          if (!hasEditProductButton && blockType === 'waymore-product') {
            toolbar.push({
              attributes: {
                class: 'icon icon-edit',
                title: 'Edit product',
              },
              command: editProductCommand,
            });
          }

          // adding create custom block button
          const createBlockCommand = 'create-block';
          const hasCreateBlockButton = toolbar.some(function (button) {
            return button.command === createBlockCommand;
          });

          // adding save as custom module button when block have children
          if (
            !hasCreateBlockButton &&
            ((this.get('tagName') === 'div' && blockType === '') ||
              blockType === 'link-button' ||
              blockType === 'social-block' ||
              blockType === 'waymore-product' ||
              blockType === 'table' ||
              blockType === 'menu-wrapper' ||
              blockType === 'section' ||
              blockType === 'column')
          ) {
            toolbar.push({
              attributes: {
                class: 'icon icon-save',
                title: 'Save as Custom Module',
              },
              command: createBlockCommand,
            });
          }
        },
      },
    });
  };

  editor.DomComponents.getTypes().forEach(addCustomToolbarButtons);
  editor.on('component:type:add', addCustomToolbarButtons);

  editor.on('component:mount', (cmp) => {
    const fullRestriction = {
      selectable: false,
      editable: false,
      collection: false,
      droppable: false,
      draggable: false,
      badgable: false,
      copyable: false,
      removable: false,
      hoverable: false,
      resizable: false,
      highlightable: false,
      layerable: false,
    };

    if (cmp.get('type') === 'menu-wrapper') {
      cmp.find('tbody, tr, td').forEach((item) => item.set(fullRestriction));
    }

    if (cmp.get('type') === 'social-icon' && cmp.findType('image')) {
      cmp.findType('image')[0].set(fullRestriction);
    }
  });

  editor.on('component:resize', () => {
    const cmp = editor.getSelected();

    if (cmp.get('type') === 'linked-image') {
      const cmpStyle = cmp?.getStyle();

      cmp.findType('linked-image-image')[0].setStyle({
        width: `${cmpStyle?.width || '100%'}`,
        height: `${cmpStyle?.height || 'auto'}`,
      });
    }
  });

  editor.on('component:styleUpdate', (cmp) => {
    if (cmp.get('type') === 'linked-image') {
      const cmpStyle = cmp.getStyle();

      cmp.findType('linked-image-image')[0].setStyle({
        width: `${cmpStyle?.width || '100%'}`,
        height: `${cmpStyle?.height || 'auto'}`,
      });
    }
  });

  // Component and Quick Settings
  editor.on('load', () => {
    editor.on('component:selected', () => {
      const selected = editor?.getSelected();
      const quickSector = document.querySelector('.gjs-sm-sector__quickSector');
      const quickSectorProperties = document.querySelector(
        '.gjs-sm-sector__quickSector > .gjs-sm-properties',
      );
      const selectImage = document.querySelector(
        '.gjs-sm-sector__quickSector .select-image-btn',
      );
      const selectProduct = document.querySelector(
        '.gjs-sm-sector__quickSector .select-product-btn',
      );
      const prodcutBlock = selected?.view?.el?.closest('.product-section');
      const discountCodeWrapper = selected.closest(
        '[data-gjs-type="discount-code"]',
      );

      selected.get('components').each((child) => {
        if (discountCodeWrapper) {
          child.set({
            selectable: false,
            editable: false,
            collection: false,
            droppable: false,
            draggable: false,
            badgable: false,
            copyable: false,
            removable: false,
            hoverable: false,
            resizable: false,
            highlightable: false,
            layerable: false,
          });
        }
      });

      // Show button under Quick Settings to show product modal
      if (selected && prodcutBlock && quickSectorProperties && !selectProduct) {
        const selectProductBtnWrapper = document.createElement('div');
        const selectProductBtn = document.createElement('button');
        const prodcutName = prodcutBlock?.dataset?.productName || '';

        selectProductBtnWrapper.className =
          'gjs-field gjs-field-full-width select-product-btn';
        selectProductBtn.textContent = 'Edit product';
        selectProductBtn.title = prodcutName;
        selectProductBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          editor.select(prodcutBlock);
          editor.runCommand('edit-product');
        });
        selectProductBtnWrapper.append(selectProductBtn);
        quickSectorProperties.prepend(selectProductBtnWrapper);
      }

      // Show button under Quick Settings to show image/icon modal
      if (
        selected &&
        quickSectorProperties &&
        !selectImage &&
        (selected?.get('type') === 'image' ||
          selected?.get('type') === 'icon-wrapper')
      ) {
        const selectedType = selected?.get('type');
        const selectImageBtnWrapper = document.createElement('div');
        const selectImageBtn = document.createElement('button');
        const editImageBtn = document.createElement('button');

        editImageBtn.textContent = 'Edit image';
        selectImageBtnWrapper.className =
          'gjs-field gjs-field-full-width select-image-btn';
        selectImageBtn.textContent =
          selectedType === 'icon-wrapper' ? 'Choose icon' : 'Choose image';
        if (!selected.view.el.src.includes('data:image/svg+xml;base64')) {
          selectImageBtn.textContent =
            selectedType === 'icon-wrapper' ? 'Change icon' : 'Change image';
        }
        selectImageBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          if (selectedType === 'icon-wrapper') {
            editor.runCommand('open-assets', { isIcon: true });
          } else {
            editor.runCommand('open-assets', { isImage: true });
          }
        });

        editImageBtn.addEventListener('click', async function (e) {
          e.preventDefault();

          editor.runCommand('tui-image-editor');
        });

        selectImageBtnWrapper.append(selectImageBtn);

        if (selectedType === 'image') {
          selectImageBtnWrapper.append(editImageBtn);
        }

        quickSectorProperties.prepend(selectImageBtnWrapper);
      }

      const setIsComponentSettingsCollapsed = editor
        .getModel()
        .get('setIsComponentSettingsCollapsed');

      if (!quickSector) {
        setIsComponentSettingsCollapsed(true);
      } else {
        if (
          selected?.get('type') === 'link' ||
          selected?.get('type') === 'link-button' ||
          selected?.get('type') === 'button' ||
          selected?.get('type') === 'embed-video'
        ) {
          setIsComponentSettingsCollapsed(true);
        } else {
          setIsComponentSettingsCollapsed(false);
        }
      }

      if (quickSector && quickSectorProperties?.childNodes.length === 0) {
        quickSector.style.display = 'none';
        setIsComponentSettingsCollapsed(true);
      }
    });
  });

  editor.on('component:styleUpdate:float', (model, style) => {
    let marginTop = '0';
    let marginRight = '0';
    let marginBottom = '0';
    let marginLeft = '0';

    if (model.getStyle()?.margin) {
      const [
        currentMarginTop,
        currentMarginRight,
        currentMarginBottom,
        currentMarginLeft,
      ] = model.getStyle()?.margin.split(' ');

      marginTop = currentMarginTop;
      marginRight = currentMarginRight;
      marginBottom = currentMarginBottom;
      marginLeft = currentMarginLeft;
    }

    if (style?.style?.float === 'inherit') {
      model.setStyle({
        ...model.getStyle(),
        display: 'block',
        margin: `${marginTop} auto ${marginBottom} auto`,
      });
    } else {
      model.setStyle({
        ...model.getStyle(),
        margin: `${marginTop} ${
          marginRight === 'auto' ? '0' : marginRight
        } ${marginBottom} ${marginLeft === 'auto' ? '0' : marginRight}`,
      });
    }
  });

  // adding listener for undo/redo changes
  // here undo/redo buttons disabled/enabled depending whether they
  // have actions to undo/redo or not
  editor.on('update', () => {
    const um = editor.UndoManager;
    const undoButton = editor.Panels.getButton('canvas-history', 'undo');
    const redoButton = editor.Panels.getButton('canvas-history', 'redo');

    if (um.hasUndo()) {
      undoButton.set('disable', false);
    } else {
      undoButton.set('disable', true);
    }

    if (um.hasRedo()) {
      redoButton.set('disable', false);
    } else {
      redoButton.set('disable', true);
    }
  });

  const weakMap = new WeakMap();

  editor.on('run:preview', () => {
    editor.DomComponents.getWrapper().onAll((comp) => {
      const attr = { ...comp.attributes };
      weakMap.set(comp, attr);
      const noEdit = {
        ...attr,
        editable: false,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: false,
        hoverable: false,
        resizable: false,
      };
      comp.set(noEdit);
    });
  });

  editor.on('stop:preview', () => {
    editor.DomComponents.getWrapper().onAll((comp) => {
      comp.set(weakMap.get(comp));
    });
  });

  // do not select classes when user selects component
  editor.on('selector:add', (selector) =>
    selector.set({
      active: false,
    }),
  );

  editor.on('block:drag:stop', (model) => {
    editor.select(model);
  });

  editor.on('component:selected', (selected) => {
    const selectedType = selected.get('type');
    const openSmBtn = pn.getButton('traits-tabs', 'open-sm-custom');

    openSmBtn.set('active', true);

    if (selected.closest('table.form-data-table')) {
      editor.select(selected.closest('table.form-data-table'));
    }

    if (selectedType === 'social-icon') {
      selected.set({
        editable: false,
      });
    }

    if (
      [
        'waymore-product',
        'social-follow',
        'discount-code',
        'qr-code',
        'form-data-table',
        'custom',
        'divider',
        'spacer',
        'section',
        'root-cell',
        'embed-video',
        'image-text',
        'linked-image',
        'icon-image',
        'icon-wrapper',
        'button',
        'image-block',
        'map',
        'link',
        'video',
        'image',
        'svg',
        'text',
      ].includes(selected.get('type')) &&
      !selected.getTrait('hideElement')
    ) {
      selected.addTrait(
        {
          type: 'hide-element',
          name: 'hideElement',
          label: 'Hide element',
          changeProp: true,
          default: 'no',
        },
        { at: selected.getTraits().length || 0 },
      );
    }

    // Remove title trait
    if (selectedType === 'spacer' || selectedType === 'divider') {
      selected.removeTrait('title');
    }

    // Remove Move up & Move down actions from toolbar
    if (
      selected.closestType('menu-wrapper') ||
      [
        'root',
        'row',
        'cell',
        'tbody',
        'tfoot',
        'thead',
        'social-follow',
      ].includes(selected.get('type'))
    ) {
      const moveDownPos = selected
        .get('toolbar')
        .findIndex((item) => item?.command === 'move-up-block');

      selected.get('toolbar').splice(moveDownPos, 2);
    }

    if (selected.attributes.attributes.isspacer) {
      selected.attributes.resizable = true;
    }

    switch (selectedType) {
      case 'root':
        setPageWrapperSector(editor, currentMode);
        break;
      case 'wrapper':
        setWrapperSector(editor, currentMode);
        break;
      case 'embed-video':
        setVideoSector(editor, currentMode);
        break;
      case 'image-block':
        setImageBlockSector(editor, currentMode);
        break;
      case 'image-text':
        setImageTextSector(editor, currentMode);
        break;
      case 'image':
        setImageSector(editor, currentMode);
        break;
      case 'text':
      case 'link':
        setTextSector(editor, currentMode);
        break;
      case 'button':
        setButtonSector(editor, currentMode);
        break;
      case 'section':
        setSectionSector(editor, currentMode);
        break;
      case 'table':
      case 'row':
      case 'cell':
        setColumnSector(editor, currentMode);
        break;
      case 'icon-wrapper':
        setIconSector(editor, currentMode);
        break;
      default: {
        if (editor.StyleManager.getSector('quickSector')) {
          editor.StyleManager.removeSector('quickSector');
        }
      }
    }
  });

  editor.on('rte:enable', (element, toolbar) => {
    const rect = element?.$el[0].getBoundingClientRect();

    if (toolbar) {
      toolbar.actionbar.parentNode.classList.remove('gjs-rte-toolbar__reverse');
    }

    if (toolbar && element?.$el[0].offsetWidth < 345 && rect.left > 345) {
      toolbar.actionbar.parentNode.classList.add('gjs-rte-toolbar__reverse');
    }
  });

  editor.on('load', () => {
    editor.Panels.getButton('traits-tabs', 'open-sm-custom').set(
      'active',
      true,
    );
    editor.Panels.getButton('blocks-tabs', 'blocks').set('active', true);
    editor.Panels.getButton('canvas-controls', 'sw-visibility').set(
      'active',
      false,
    );
  });

  document.addEventListener('keydown', function (event) {
    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.keyCode === 187 || event.keyCode === 107)
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-in');
    }

    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.key === '-' || event.key === '_')
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-out');
    }

    if (
      event.shiftKey &&
      event.ctrlKey &&
      (event.key === '0' || event.key === ')')
    ) {
      event.preventDefault();
      editor.runCommand('editor-zoom-reset');
    }
  });
};
