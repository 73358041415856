import { useReducer, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { principalIdSelector } from 'store/selectors';
import { defineMessages, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import produce from 'immer';
import merge from 'lodash.merge';
import {
  FONTS,
  LOAD_STATUS,
  TEMPLATE_STATUS,
  BASE64_REGEX,
} from 'appConstants';
import {
  buildQueryString,
  closeEmbedEditor,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import {
  changeStatusTemplate,
  cleanTemplate,
  duplicateTemplate,
  getSystemTemplate,
  getTemplate,
  getTemplates,
  sendTestEmails,
  updateSystemTemplate,
  updateTemplate,
} from 'store/actions';
import { useQuery } from 'hooks';
import { useToggle, useToggleTypes } from './useToggle';

const messages = defineMessages({
  errorMessage: {
    id: 'toast.error-message',
    defaultMessage: 'Something went wrong. Try again later',
  },
  templateNotFoundMessage: {
    id: 'template.not-found',
    defaultMessage: 'Template not found',
  },
  saveInfoSuccessMessage: {
    id: 'template.info.save.success-message',
    defaultMessage: 'Template settings has been saved',
  },
  saveSuccessMessage: {
    id: 'template.save.success-message',
    defaultMessage: 'Template has been saved',
  },
  testEmailSuccessMessage: {
    id: 'test-email.success-message',
    defaultMessage: 'Your emails have been sent',
  },
  testEmailErrorMessage: {
    id: 'toast.error-message',
    defaultMessage: 'Something went wrong. Try again later',
  },
  convertSuccessMessage: {
    id: 'template.convert.success-message',
    defaultMessage: 'Template successfully converted to system',
  },
});

const templateLoadingStatuses = {
  isChangeInfoLoading: 'changeInfoLoading',
  isSendEmailLoading: 'sendEmailLoading',
  isSaveTemplateLoading: 'saveTemplateLoading',
  isChangeStatusLoading: 'changeStatusLoading',
  isConvertTemplateLoading: 'convertTemplateLoading',
};

const CHANGE_SETTINGS = 'CHANGE_SETTINGS';
const SET_TEMPLATE = 'SET_TEMPLATE';
const SET_HAVE_CHANGES = 'SET_HAVE_CHANGES';

const initialState = {
  haveChanges: false,
  template: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case SET_HAVE_CHANGES: {
      draft.haveChanges = payload;

      break;
    }
    case CHANGE_SETTINGS: {
      draft.template = merge({}, draft.template, payload);

      break;
    }
    case SET_TEMPLATE: {
      draft.template = payload;

      break;
    }
    default:
      throw new Error('Invalid type in useTemplate reducer');
  }
});

export const useTemplate = () => {
  const { id } = useParams();
  const history = useHistory();
  const { embed, system } = useQuery();

  const intl = useIntl();

  const templateState = useSelector((state) => state.template.template);
  const token = useSelector((state) => state.user.token);
  const currentUser = useSelector(principalIdSelector);

  const reduxDispatch = useDispatch();

  const { show, handleShow } = useToggle();

  const [{ template, haveChanges }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const [loadingStatus, setLoadingStatus] = useState(LOAD_STATUS.IDLE);

  const [generalSettings, setGeneralSettings] = useState({
    width: '700',
    padding: 20,
    bgColor: '#ffffff',
    headerFonts: FONTS[0].value,
    paragraphFonts: FONTS[0].value,
    lineSpacing: '100%',
    paragraphBottomSpace: false,
    underlineLinks: false,
    h1HeadingFontSize: 40,
    h1HeadingFontColor: '#000',
    h1HeadingBold: false,
    h1HeadingItalic: false,
    h1HeadingBottomSpace: false,
    h2HeadingFontSize: 32,
    h2HeadingFontColor: '#000',
    h2HeadingBold: false,
    h2HeadingItalic: false,
    h2HeadingBottomSpace: false,
    h3HeadingFontSize: 28,
    h3HeadingFontColor: '#000',
    h3HeadingBold: false,
    h3HeadingItalic: false,
    h3HeadingBottomSpace: false,
    h4HeadingFontSize: 24,
    h4HeadingFontColor: '#000',
    h4HeadingBold: false,
    h4HeadingItalic: false,
    h4HeadingBottomSpace: false,
    h5HeadingFontSize: 20,
    h5HeadingFontColor: '#000',
    h5HeadingBold: false,
    h5HeadingItalic: false,
    h5HeadingBottomSpace: false,
    h6HeadingFontSize: 16,
    h6HeadingFontColor: '#000',
    h6HeadingBold: false,
    h6HeadingItalic: false,
    h6HeadingBottomSpace: false,
  });

  const updateRequest = system ? updateSystemTemplate : updateTemplate;

  useEffect(() => {
    if (token) {
      reduxDispatch(getTemplates());
      reduxDispatch(
        system ? getSystemTemplate(id) : getTemplate({ id, currentUser }),
      )
        .unwrap()
        .catch(() => {
          showErrorMessage(
            intl.formatMessage(messages.templateNotFoundMessage),
          );

          history.replace('/');
        });
    }
  }, [reduxDispatch, token, intl, history, id, system, currentUser]);

  // syncing local state with global
  useEffect(() => {
    dispatch({ type: SET_TEMPLATE, payload: templateState.template });
  }, [templateState.template]);

  useEffect(() => {
    return () => {
      reduxDispatch(cleanTemplate());
    };
  }, [reduxDispatch]);

  const handleChangeTemplate = useCallback(() => {
    dispatch({ type: SET_HAVE_CHANGES, payload: true });
  }, []);

  const handleChangeSettings = (payload) => {
    dispatch({ type: CHANGE_SETTINGS, payload });
  };

  const handleChangeInfo = useCallback(
    async (payload) => {
      setLoadingStatus(templateLoadingStatuses.isChangeInfoLoading);
      handleChangeSettings(payload);

      try {
        await reduxDispatch(
          updateRequest({
            id: template.id,
            payload,
          }),
        ).unwrap();

        setLoadingStatus(LOAD_STATUS.RESOLVED);
        handleShow(useToggleTypes.showInfoModal);
        showSuccessMessage(intl.formatMessage(messages.saveInfoSuccessMessage));
      } catch {
        setLoadingStatus(LOAD_STATUS.REJECTED);
      }
    },
    [reduxDispatch, updateRequest, template, intl, handleShow],
  );

  const handleChangeGeneralSettings = (name, value) => {
    setGeneralSettings({ ...generalSettings, [name]: value });
  };

  const handleChangeStatus = useCallback(async () => {
    setLoadingStatus(templateLoadingStatuses.isChangeStatusLoading);
    const isPublishing = template.status === TEMPLATE_STATUS.DRAFT;

    try {
      await reduxDispatch(
        changeStatusTemplate({
          id: template.id,
          status: isPublishing
            ? TEMPLATE_STATUS.PUBLISHED
            : TEMPLATE_STATUS.DRAFT,
        }),
      ).unwrap();

      await reduxDispatch(getTemplate({ id, currentUser })).unwrap();

      setLoadingStatus(LOAD_STATUS.RESOLVED);

      if (isPublishing) {
        handleShow(useToggleTypes.showPublishedModal);
      }
    } catch {
      showErrorMessage(intl.formatMessage(messages.errorMessage));
      setLoadingStatus(LOAD_STATUS.REJECTED);
    }
  }, [handleShow, id, intl, reduxDispatch, template, currentUser]);

  const handleSaveTemplate = async (editor) => {
    setLoadingStatus(templateLoadingStatuses.isSaveTemplateLoading);

    try {
      const payload = {
        name: template.name,
        tags: template.tags,
        description: template.description,
        template: {
          html: editor.getHtml(),
          css: editor.getCss(),
          style: editor.getStyle(),
          customStyles: `
            @media (max-width: 992px) {
              .dnd-hide-element-tablet { display:none !important; }
            }
            @media (max-width: 480px) {
              .dnd-hide-element-mobile { display:none !important; }
            }
            `,
          components: editor.getComponents(),
        },
      };
      const matchedBase64ForHtml = editor.getHtml().match(BASE64_REGEX);
      const matchedBase64ForCss = editor.getCss().match(BASE64_REGEX);

      if (matchedBase64ForHtml || matchedBase64ForCss) {
        throw showErrorMessage(
          'Base64 encoded content is not allowed in the template. Please check and remove them before saving the template',
        );
      }

      await reduxDispatch(updateRequest({ id: template.id, payload })).unwrap();

      if (embed) {
        if (template.status === 'draft') {
          try {
            await reduxDispatch(
              changeStatusTemplate({
                id: template.id,
                status: TEMPLATE_STATUS.PUBLISHED,
              }),
            ).unwrap();
          } catch {
            showErrorMessage(intl.formatMessage(messages.errorMessage));
          }
        }

        closeEmbedEditor(template.id);
      }

      dispatch({ type: SET_HAVE_CHANGES, payload: false });
      showSuccessMessage(intl.formatMessage(messages.saveSuccessMessage));
      setLoadingStatus(LOAD_STATUS.RESOLVED);
    } catch (e) {
      setLoadingStatus(LOAD_STATUS.REJECTED);
    }
  };

  const handleConvertToSystem = useCallback(async () => {
    setLoadingStatus(templateLoadingStatuses.isConvertTemplateLoading);
    try {
      const params = { xtype: true };

      await reduxDispatch(
        duplicateTemplate({ id: template.id, copySharedInfo: false, params }),
      ).unwrap();

      const publishedSearch = buildQueryString({
        status: TEMPLATE_STATUS.PUBLISHED,
        embed,
      });

      showSuccessMessage(intl.formatMessage(messages.convertSuccessMessage));
      setLoadingStatus(LOAD_STATUS.RESOLVED);
      history.push({
        pathname: '/',
        search: publishedSearch,
      });
    } catch {
      setLoadingStatus(LOAD_STATUS.REJECTED);
    }
  }, [embed, history, intl, reduxDispatch, template]);

  const handleSendTestEmails = async (from, to) => {
    setLoadingStatus(templateLoadingStatuses.isSendEmailLoading);

    try {
      await reduxDispatch(
        sendTestEmails({
          from,
          to,
          id: template.id,
        }),
      ).unwrap();

      showSuccessMessage(intl.formatMessage(messages.testEmailSuccessMessage));
      setLoadingStatus(LOAD_STATUS.RESOLVED);
      handleShow(useToggleTypes.showTestEmailModal);
    } catch (error) {
      setLoadingStatus(LOAD_STATUS.REJECTED);
    }
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/',
      search: buildQueryString({
        status: TEMPLATE_STATUS.PUBLISHED,
        embed,
      }),
    });
  };

  return {
    template,
    generalSettings,
    haveChanges,
    show,
    isError: templateState.isError,
    isLoading: !templateState.isLoaded && templateState.isLoading,
    isUpdateLoading:
      loadingStatus === templateLoadingStatuses.isSaveTemplateLoading,
    isStatusLoading:
      loadingStatus === templateLoadingStatuses.isChangeStatusLoading,
    isConvertLoading:
      loadingStatus === templateLoadingStatuses.isConvertTemplateLoading,
    isSendEmailLoading:
      loadingStatus === templateLoadingStatuses.isSendEmailLoading,
    isChangeInfoLoading:
      loadingStatus === templateLoadingStatuses.isChangeInfoLoading,
    handleChangeTemplate,
    handleChangeInfo,
    handleChangeStatus,
    handleSaveTemplate,
    handleConvertToSystem,
    handleSendTestEmails,
    handleGoBack,
    handleShow,
    handleChangeGeneralSettings,
    setGeneralSettings,
  };
};
