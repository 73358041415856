import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomBlock,
  getCustomBlocks,
  createCustomBlock,
  updateCustomBlock,
  updateSystemCustomBlock,
} from 'store/actions';
import { isAdminSelector } from 'store/selectors';
import { BLOCKS_CATEGORIES } from 'appConstants';

export function useBlocks() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = useSelector(isAdminSelector);

  const setBlocks = (editor, searchTerm = '') => {
    const customBlocksGroupList = editor
      .getModel()
      .get('customBlocksGroupList');

    const { BlockManager: bm } = editor;
    const type = editor.getModel().get('blocksType');
    const moduleCats = [];

    customBlocksGroupList.forEach(({ name }) => moduleCats.push(name));

    const categories =
      type === 'modules'
        ? [...moduleCats, 'My modules']
        : BLOCKS_CATEGORIES[type];

    const allBlocks = bm.getAll();

    const filtered = allBlocks.filter((block) => {
      // due to the weirdest bug in chrome block.get('category') returns
      // object, but in Firefox it returns string. Just as workaround we
      // are checking type of the returned value and getting an id of the
      // category. For more info check this issue - https://github.com/artf/grapesjs/issues/3257
      let category = block.get('category');
      category = typeof category === 'string' ? category : category.id;

      return (
        categories?.includes(category) &&
        block.get('label').toLowerCase().includes(searchTerm)
      );
    });

    bm.render(filtered, {
      ignoreCategories: Boolean(searchTerm),
    });
  };

  const getBlocks = useCallback(async () => {
    try {
      const res = await dispatch(getCustomBlocks()).unwrap();

      return res;
    } catch {
      return [];
    }
  }, [dispatch]);

  const saveBlock = useCallback(
    async (payload, isAdmin = false) => {
      setIsLoading(true);
      try {
        if (payload.id) {
          if (isAdmin) {
            const res = await dispatch(
              updateSystemCustomBlock(payload),
            ).unwrap();

            return res;
          } else {
            const res = await dispatch(updateCustomBlock(payload)).unwrap();

            return res;
          }
        }

        const res = await dispatch(createCustomBlock(payload)).unwrap();

        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const deleteBlock = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        await dispatch(deleteCustomBlock(id)).unwrap();
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const setCustomModulesGroupActions = (
    categories,
    deleteModuleCategoryConfirmModal,
    editModuleCategoryModal,
  ) => {
    categories.forEach((categoryItem) => {
      if (
        isAdmin &&
        ![
          ...BLOCKS_CATEGORIES['blocks'],
          ...BLOCKS_CATEGORIES['waymore'],
          'My modules',
        ].includes(categoryItem.id)
      ) {
        const buttonsWrapper = document.createElement('div');
        buttonsWrapper.className = 'custom-module-buttons-wrapper';

        const deleteGroupButton = document.createElement('button');
        deleteGroupButton.title = 'Delete Group';
        deleteGroupButton.className = 'delete-custom-module-btn secondary';
        deleteGroupButton.innerHTML = '<span class="icon icon-delete"></span>';
        deleteGroupButton.addEventListener('click', async function () {
          deleteModuleCategoryConfirmModal(categoryItem.id);
        });

        const editGroupButton = document.createElement('button');
        editGroupButton.title = 'Edit Group';
        editGroupButton.className = 'edit-custom-module-btn secondary';
        editGroupButton.innerHTML = '<span class="icon icon-edit"></span>';
        editGroupButton.addEventListener('click', async function () {
          editModuleCategoryModal(categoryItem.id);
        });

        buttonsWrapper.appendChild(deleteGroupButton);
        buttonsWrapper.appendChild(editGroupButton);

        categoryItem.view.el.appendChild(buttonsWrapper);
      }
    });
  };

  return {
    isBlocksLoading: isLoading,
    getBlocks,
    saveBlock,
    deleteBlock,
    setBlocks,
    setCustomModulesGroupActions,
  };
}
