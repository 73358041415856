import axios from 'axios';

const httpUpdateUserSettings = (payload) => {
  return axios.put('/api/v2/usersettings', payload);
};

const httpGetUserSettings = (userId) => {
  return axios.post(`/api/v2/usersettings/${userId}`);
};

export { httpUpdateUserSettings, httpGetUserSettings };
